@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .flex-Center {
        @apply flex items-center justify-center
    }
    .line-sm {line-height: 1em !important;}
    .line-lg {line-height: 1.2em !important;}

    @media (max-width: 768px) { /* Tailwind의 md 브레이크포인트는 기본적으로 768px입니다. */
        .display-contents {display: contents;}
    }

    .white {
        transition: all 0.25s linear;
        background-color: white;
    }

    .dark {
        transition: all 0.25s linear;
        background-color: #1e2937;
        min-height: 100vh;
    }
    
    .hide-scrollbar::-webkit-scrollbar {
        display: none;
    }
}

.anchor {
    display: none
}

.w-md-editor-toolbar {
    border-radius: 8px 8px 0px 0px !important;
    padding: 10px;
}

.w-md-editor-content {
    padding: 10px
}